@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
html,
body {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 16px;
}
/* https://mui.com/material-ui/customization/z-index/ */
.swal2-container {
  /* looks production env will overwrite this style, so, add important */
  z-index: 1501 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}
